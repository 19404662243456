import { App } from 'projects/shared/environments/app';

export const environment = {
  app: App.MANAGER_APP,
  production: false,
  apiURL: '/backend/',
  masterURL: 'https://master-bhdev.weptun.de/main/',
  participantURL: 'https://participant-bhdev.weptun.de',
  hadURL: 'https://had-bhdev.weptun.de',
  websocketURL: 'wss://manager-bhdev.weptun.de/backend/ws',
  playlistBaseUrl: 'https://static.beyond.host/music',
  title: 'Manager App',
  useHeartbeat: false,
};
