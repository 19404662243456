import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../../shared/services/auth.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { ResettableService } from '../../../../shared/services/resettable.service';

@Injectable()
export class ManagerAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private resetService: ResettableService,
    private environmentService: EnvironmentService
  ) {}

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const hotelId = this.localStorageService.loadHotel();

    if (!hotelId) {
      this.resetService.resetNow.next(true);
      this.authService.logout();
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return of(false);
    }

    if (this.authService.loggedIn && hotelId) {
      return of(true);
    }

    // Verify if the api_gm_cookie is set by getting a valid response back
    return this.authService.checkCredentials().pipe(
      switchMap(() => this.environmentService.loadManagementData()),
      map(() => {
        return true;
      }),
      catchError((err) => {
        console.log('Error in ManagerAuthGuard', err);
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
