import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ManagerNotification } from '../../../../shared/models/manager-notification.model';
import { ManagerPushToken } from '../../../../shared/models/manager-push-token.model';
import { ApplicationHttpClient } from '../../../../shared/services/application-http-client';

@Injectable({
  providedIn: 'root',
})
export class ManagerNotificationService {
  private baseUrl = 'api/manager-notification';

  private state$: BehaviorSubject<ManagerNotification[]> = new BehaviorSubject(
    null
  );
  private markAsDirty$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: ApplicationHttpClient) {}

  public getNotifications(force?: true): Observable<ManagerNotification[]> {
    if (force || !this.state$.value) {
      this.state$.next(null);
      this.http
        .get<ManagerNotification[]>(`${this.baseUrl}/all`)
        .pipe(take(1))
        .subscribe((notifications) => {
          this.state$.next(notifications);
          this.markAsDirty$.next(false);
        });
    }

    return this.state$.asObservable();
  }

  public getNotificationsMarkedAsDirty(): Observable<boolean> {
    return this.markAsDirty$.asObservable();
  }

  public markNotificationReceived(): void {
    this.markAsDirty$.next(true);
  }

  public updateNotification(
    notification: ManagerNotification
  ): Observable<ManagerNotification> {
    return this.http
      .put<ManagerNotification>(`${this.baseUrl}?updateAll=true`, notification)
      .pipe(
        tap((updatedNotification) => {
          const notifications = this.state$.getValue();
          const updatedNotifications = notifications.filter(
            (n) => n.type !== updatedNotification.type
          );
          this.state$.next(updatedNotifications);
        })
      );
  }

  public deleteAllNotifications(): Observable<void> {
    return this.http
      .delete<void>(`${this.baseUrl}/all`)
      .pipe(tap(() => this.state$.next([])));
  }

  public registerPushToken(token: ManagerPushToken): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/push-token`, token);
  }
}
