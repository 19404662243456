import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { environment } from '../environments/environment';
import { firebaseConfig } from '../environments/firebase-config';
import { WebPushNotificationService } from './services/web-push-notification.service';

@Component({
  selector: 'app-manager-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isFullyBrowser = 'fully' in window;

  private TITLE = environment.title;

  constructor(
    private titleService: Title,
    private webPushNotificationService: WebPushNotificationService
  ) {
    const app = initializeApp(firebaseConfig);
    app.automaticDataCollectionEnabled = false;
    getMessaging(app);
    this.webPushNotificationService.retrieveToken();
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.TITLE);
  }
}
