import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ToastrModule } from 'ngx-toastr';
import { ProjectModule } from '../../../shared/modules/project.module';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManagerAuthGuard } from './guards/manager-auth-guard';

@NgModule({
  imports: [
    AppRoutingModule,
    ProjectModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  declarations: [
    // Components
    AppComponent,
  ],
  providers: [
    // guards
    ManagerAuthGuard,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
