import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ILogin } from '../../../../../shared/components/generic-login/generic-login.component';
import { AuthService } from '../../../../../shared/services/auth.service';
import { BhToastService } from '../../../../../shared/services/bh-toast.service';
import { EnvironmentService } from '../../../../../shared/services/environment.service';
import { LocalStorageService } from '../../../../../shared/services/local-storage.service';
import { LoggerService } from '../../../../../shared/services/logger.service';
import { WebPushNotificationService } from '../../services/web-push-notification.service';

@Component({
  selector: 'app-manager-login',
  templateUrl: './manager-login.component.html',
  styleUrls: ['./manager-login.component.scss'],
})
export class ManagerLoginComponent implements ILogin {
  constructor(
    private authService: AuthService,
    private toastService: BhToastService,
    private router: Router,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService,
    private logger: LoggerService,
    private webPushNotificationService: WebPushNotificationService
  ) {}

  public login(username: string, password: string, returnUrl: string): void {
    this.authService
      .loginGM(username, password)
      .pipe(
        catchError((err) => {
          this.toastService.error('manager.login.wrong-credentials');
          return throwError(err);
        }),
        switchMap((hotelId) => {
          this.localStorageService.saveHotel(hotelId);
          this.authService.login();
          return this.environmentService.loadHotelData().pipe(
            catchError((err) => {
              this.toastService.error('manager.login.error-data');
              return throwError(err);
            })
          );
        }),
        take(1)
      )
      .subscribe(
        () => {
          this.webPushNotificationService.register();
          this.router.navigateByUrl(returnUrl);
        },
        (err) => this.logger.logProdError(err)
      );
  }
}
