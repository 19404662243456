export const firebaseConfig = {
  apiKey: 'AIzaSyDbbn18xaQNs6MHQeLpNdJ-TisueOoa88A',
  authDomain: 'beyond-host.firebaseapp.com',
  projectId: 'beyond-host',
  storageBucket: 'beyond-host.appspot.com',
  messagingSenderId: '1057421608091',
  appId: '1:1057421608091:web:83c285b5c160b5518eb23e',
};

export const vapidKey =
  'BGcRsgQjXJ12wmhAG0mrSVYLeE1U4mOOa1XIzrNH8XOI0YYiZqEcmUluCxxFD0__zrQhahimeM-gZc7K6FdQV9k';
