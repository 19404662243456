import { Platform } from '@angular/cdk/platform';
import { Injectable, OnDestroy } from '@angular/core';
import { getMessaging, getToken } from 'firebase/messaging';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription, from, fromEvent } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { GenericModalComponent } from '../../../../shared/components/generic-modal/generic-modal.component';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { LocalizationService } from '../../../../shared/services/localization.service';
import { vapidKey } from '../../environments/firebase-config';
import { ManagerNotificationService } from './manager-notification.service';

@Injectable({
  providedIn: 'root',
})
export class WebPushNotificationService implements OnDestroy {
  private askedForPermission = false;

  private subscription: Subscription;
  private broadcast = new BroadcastChannel('messaging-sw');

  constructor(
    private managerNotificationService: ManagerNotificationService,
    private localizationService: LocalizationService,
    private platform: Platform,
    private localStorageService: LocalStorageService,
    private modalService: MDBModalService
  ) {}

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public register(): void {
    if (this.askedForPermission) {
      return;
    }

    if (!('Notification' in window)) {
      if (this.showIosInstallModal()) {
        this.openIosModal();
      }
      return;
    }
    console.log('Requesting permission...');
    this.askedForPermission = true;
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.retrieveToken();
      }
    });
  }

  public subscribeToMessages(): void {
    console.log('Subscribing to messages');
    this.clearNotificationsOnVisibilityChange();

    this.broadcast.onmessage = (event) => {
      this.managerNotificationService.markNotificationReceived();
    };
  }

  public retrieveToken(): void {
    if (!('Notification' in window) || Notification.permission !== 'granted') {
      console.log('Notifications not granted');
      return;
    }
    from(getToken(getMessaging(), { vapidKey: vapidKey }))
      .pipe(
        take(1),
        switchMap((currentToken) =>
          this.managerNotificationService.registerPushToken({
            value: currentToken,
            language: this.localizationService.currentLangKey,
          })
        )
      )
      .subscribe(
        () => {
          if (this.showIosInstallModal()) {
            this.openIosModal();
          }
          console.log('Push token registered.');
        },
        (err) => console.log('Push token registration failed.', err)
      );
  }

  private showIosInstallModal(): boolean {
    // detect if the device is on iOS
    const isIos = this.platform.IOS;

    // check if the device is in standalone mode
    const isInStandaloneMode = () => {
      return (
        'standalone' in (window as any).navigator &&
        (window as any).navigator.standalone
      );
    };

    // show the modal only once
    const iosInstallModalShown = this.localStorageService.loadPwaModal();
    const shouldShowModalResponse =
      isIos && !isInStandaloneMode() && !iosInstallModalShown;
    if (shouldShowModalResponse) {
      this.localStorageService.savePwaModal(true);
    }
    return shouldShowModalResponse;
  }

  private openIosModal(): void {
    this.modalService.show(GenericModalComponent, {
      ignoreBackdropClick: false,
      keyboard: true,
      class: 'modal-dialog-centered',
      data: {
        modalTitleTxt: 'manager.notifications.pwa-ios-prompt.title',
        modalBodyTxt: 'manager.notifications.pwa-ios-prompt.body',
        showCancelButtonInHeader: true,
        hideFooter: true,
      },
    });
  }

  private clearNotificationsOnVisibilityChange(): void {
    this.broadcast.postMessage({ action: 'clearNotifications' });

    this.subscription = fromEvent(document, 'visibilitychange')
      .pipe(filter(() => document.visibilityState === 'visible'))
      .subscribe(() =>
        this.broadcast.postMessage({ action: 'clearNotifications' })
      );
  }
}
