import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedOutGuard } from 'projects/shared/guards/logged-out-guard';
import { sharedRoutes } from '../../../shared/routes/route.routing';
import { ManagerLoginComponent } from './components/login/manager-login.component';
import { ManagerAuthGuard } from './guards/manager-auth-guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ManagerAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'main',
        loadChildren: () =>
          import('./modules/main/main.module').then((m) => m.MainModule),
      },
    ],
  },
  {
    path: 'login',
    component: ManagerLoginComponent,
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot([...sharedRoutes, ...routes], {
      // Do only tracing use for debugging
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
